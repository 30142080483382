.image-viewer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.nav-buttons {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: space-between;
    align-items: center;
}

.nav-button {
    position: absolute;
    top: 50%;
    background-color: rgba(255, 255, 255);
    color: white;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    z-index: 1;
    border-radius: 50%;
    margin: 0 1rem;
    display: flex;
}

.nav-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

.back-button {
    transform: scaleX(-1);
    left: 0;
}

.forward-button {
    right: 0;
}

.image-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

.main-container {
    position: absolute;
  top: 0%;
  left: 0%;
    z-index: 10; /* Higher stacking order */
  }